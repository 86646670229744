import '../App.css';
import React, { useEffect, useRef } from "react";

function HtmlDoc(props) {
    const iframeRef = useRef(null);

    useEffect(() => {
        // Simple function to handle iframe load
        const handleIframeLoad = () => {
            if (iframeRef.current) {
                try {
                    // Get the iframe window
                    const iframeWindow = iframeRef.current.contentWindow;

                    // Set a fixed height that's generous enough for most content
                    iframeRef.current.style.height = '1500px'; // Adjust this value as needed

                    // For Chrome mobile: add viewport meta if possible
                    try {
                        const iframeDoc = iframeWindow.document;

                        // Only proceed if we have access to the iframe document (same-origin)
                        if (iframeDoc) {
                            // Find the viewport meta tag if it exists
                            let viewportMeta = iframeDoc.querySelector('meta[name="viewport"]');

                            // If it doesn't exist, create one
                            if (!viewportMeta) {
                                viewportMeta = iframeDoc.createElement('meta');
                                viewportMeta.name = 'viewport';
                                iframeDoc.head.appendChild(viewportMeta);
                            }

                            // Set the content with a slight zoom out to prevent Chrome's auto-zoom
                            viewportMeta.content = 'width=device-width, initial-scale=0.99, maximum-scale=1.0, user-scalable=no, shrink-to-fit=no';
                        }
                    } catch (error) {
                        // Ignore errors accessing the iframe document (CORS)
                        console.log("Could not access iframe document");
                    }
                } catch (error) {
                    console.log("Error handling iframe:", error);
                }
            }
        };

        // Add event listener for iframe load
        const iframe = iframeRef.current;
        if (iframe) {
            iframe.addEventListener('load', handleIframeLoad);
        }

        // Cleanup
        return () => {
            if (iframe) {
                iframe.removeEventListener('load', handleIframeLoad);
            }
        };
    }, []);

    return (
        <div className="responsive-iframe-container">
            <iframe
                ref={iframeRef}
                src={props.htmlDoc}
                title="html doc"
                className="responsive-iframe"
                scrolling="yes" // Allow scrolling within the iframe
            />
        </div>
    );
}

export default HtmlDoc;