import '../App.css';
import React from "react";
function MainPasswordForm(props) {
    let onFormSubmit = e => {
        e.preventDefault();
        props.handleSubmitClick(e, props.setShowImages, props.setShowVideos, props.setImages,
            props.setVideos, props.setShowHtmlDoc, props.setHtmlDoc,
            props.setYouTubeList, props.setShowYouTubeList, props.password,
            props.baseDomainUrl, props.serviceUrlResource, props.setShowInputForm,
            props.setShowAudio, props.setAudio, props.setAnalogArchiveMediaFolder,
            props.setServerFolder, props.setShowAnalogArchiveMediaFolder, props.setShowServerFolder,
            props.setShowDeleteForm)
    }
    return(
        <>
            <div id="passworddiv" className="App" onSubmit={onFormSubmit}>
                <form>
                <input data-testid="password" type='password' name='password' id='password' placeholder="password"
                       onChange={(element) => props.handlePasswordChange(element, props.setPassword)} autoFocus="autofocus" />
                <input data-testid="submit" type='submit' name='submit' id='submit' value='GO!' />
                </form>
            </div>
        </>
    );
}

export default MainPasswordForm;